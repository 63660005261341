var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "svg-icons" }, [
    _c(
      "svg",
      { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 20 20" } },
      [
        _c("path", {
          attrs: {
            d: "M1 10h3v10H1V10zM6 0h3v20H6V0zm5 8h3v12h-3V8zm5-4h3v16h-3V4z"
          }
        })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }